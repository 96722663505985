* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}

body {
  float: left;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  font-family: "Manrope", sans-serif !important;
  font-weight: 500;
}

.wrapper {
  float: left;
  width: 100%;
  overflow: hidden;
}

/* dashboard color change var */
:root {
  --mainBackground: #fff;
  --loginColor: #407bff;
  --maincolor: #0e39b6;
  --maincolortwo: #008fff;
}

.batch_code {
  padding: 9px 20px;
  background-color: #fadddd;
  color: red;
  height: auto;
  line-height: normal;
  float: left;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}

/* login css start here */
.mainloginwrps {
  float: left;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: var(--mainBackground);
  align-items: center;
}

.login_wrapper {
  float: left;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  display: flex;
}

.login_wrap_slider {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  flex-direction: column;
}

.login_wrap_slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.login_wrap_slider h4 {
  float: left;
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 25px 0 9px;
  font-weight: 600;
  letter-spacing: 0.6px;
}

.loginTEXTS h4 {
  margin: 0px;
  float: left;
  width: 100%;
  font-size: 24px;
  text-align: left;
}

.login_wrap_slider p {
  color: #dbe5ff;
  text-align: center;
  font-size: 14px;
  margin: 0 0 0;
  float: left;
  width: 75%;
}

.login_form_wraps {
  float: left;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 65%;
  padding: 2% 8%;
  flex: 1;
}

.loginTEXTS {
  float: left;
  width: 100%;
  text-align: center;
  margin: 0 0 15px;
  position: relative;
}

.loginTEXTS h1 {
  float: left;
  width: 100%;
  margin-bottom: 17px;
}

.form-group {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.form-group label,
.common-label {
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 5px;
}

.forgot_password_link {
  padding: 6px 0px;
  border-radius: 8px;
  line-height: 25px;
  position: relative;
  font-size: 14px;
  outline: none !important;
  border: 0px !important;
}

.formInput {
  float: left;
  width: 100%;
  position: relative;
}

.formInput input {
  height: 40px;
  border-radius: 8px;
  padding: 7px 12px;
  font-size: 14px;
  box-shadow: none !important;
  font-weight: 500;
}

.formInput .react-datepicker-wrapper {
  width: 100%;
  float: left;
}

.formInput .react-datepicker-wrapper input {
  border: 1px solid #dee2e6 !important;
  width: 100%;
  float: left;
  outline: none !important;
}

.errormendatorr {
  float: left;
  width: 100%;
  font-size: 13px;
  color: red;
  margin: 5px 0 0;
}

.errormendatorr i {
  margin-right: 4px;
}

.eyeshowbtn {
  border: 0px;
  background: transparent;
  position: absolute;
  right: 10px;
  top: 0px;
  line-height: normal;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-group-btn {
  float: left;
  width: 100%;
  margin: 5px 0 0;
}

.loginCmn_btn {
  background-color: var(--loginColor);
  border-color: var(--loginColor) !important;
  color: #fff;
  padding: 16px 22px;
  border-radius: 1rem;
  line-height: 25px;
  position: relative;
}

.loginCmn_btn:hover {
  color: var(--maincolortwo);
}

.loginCmn_btn .spinner-border {
  width: 20px;
  height: 20px;
  float: left;
  border-width: 3px;
  margin: 2px 5px 0px 0px;
}

/* login css end here */

/* admin css start here */
.adminmain {
  display: flex;
  height: 100vh;
  justify-content: flex-start;
  /* column-gap: 10px; */
  align-items: center;
}

.adminmain {
  float: left;
  position: relative;
  width: 100%;
  background-color: #fff;
}

.dashboard_wrapper {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  /* border-top: 1px solid #edeff1; */
  padding-top: 1.3rem;
  padding: 1rem;
}

.dashboard-grey {
  background-color: #edeff1 !important;
  padding: 0rem !important;
}

.dashboard-page-bg {
  background-color: #edeff1 !important;
}

.dashboard_wrapper_form {
  float: left;
  position: relative;
  width: 100%;
  height: calc(100vh - 152px);
}

.sidebar {
  background-color: #0e0e30;
  flex-basis: 265px;
  float: left;
  height: 100vh;
  width: 100%;
  padding: 8px 8px;
  color: #fff;
}

#view-vendor-avatar img {
  max-width: 95px;
  max-height: 95px;
}

.navbarHeader {
  border-bottom: 1px solid #008fff;
  float: left;
  height: 71px;
  padding: 22px 25px;
  width: 100%;
}

li button {
  border-radius: 0px !important;
}

input,
select,
button {
  border-radius: 6px !important;
}

.flex-column.nav {
  float: left;
  width: 100%;
}

.flex-column .nav-link {
  float: left;
  width: 90%;
  padding: 9px 5px;
  margin: 5px auto;
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  position: relative;
  transition: 0.5s;
  gap: 10px;
  margin-left: 1.5rem;
}

.flex-column .nav-link.active {
  transition: 0.5s;
  background-color: var(--loginColor) !important;
  color: #fff;
  margin-right: 1.5rem;
}

.flex-column .nav-link:hover {
  background-color: #000;
}

.flex-column .nav-link.active span {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.flex-column .nav-link i {
  font-size: 21px;
  margin: 0 0 4px;
  color: var(--loginColor);
}

.flex-column .nav-link img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  /* margin: 0 0 6px; */
}

.flex-column .nav-link.active img {
  -webkit-filter: invert(91%) sepia(67%) saturate(1438%) hue-rotate(181deg)
    brightness(111113%) contrast(11115%);
  filter: invert(91%) sepia(67%) saturate(1438%) hue-rotate(181deg)
    brightness(111113%) contrast(11115%);
}

.flex-column .nav-link.active i {
  color: #fff;
}

.flex-column .nav-link span {
  color: #fff;
  line-height: 12px;
  font-weight: 400;
  font-size: 16px;
}

.panelwrapper {
  float: left;
  width: calc(100% - 125px);
  height: calc(100vh - 20px);
}

.head_section {
  float: left;
  width: 100%;
  padding: 0px 0px 0px 15px;
  background-color: #fff;
  margin: 0 0 10px;
  position: relative;
  box-sizing: border-box;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head_section h4 {
  float: left;
  width: auto;
  font-size: 16px;
  margin: 0px;
  position: relative;
  text-transform: capitalize;
}

.panelContentwrapper {
  float: left;
  width: 100%;
  height: calc(100vh - 73px);
  padding: 15px 15px;
  background-color: #fff;
  position: relative;
}

/* admin css end here */

/* header css start here */
.dropdownwraps {
  float: right;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.user_DETAILS {
  float: left;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 16px;
  color: #000;
  cursor: pointer;
  column-gap: 8px;
  border: 1px solid #e3e5e9;
  border-radius: 6px;
}

.user_DETAILS i {
  font-size: 20px;
}

.add_cartbtn {
  float: left;
  width: auto;
  height: 100%;
  border: 0;
  background: transparent;
  padding: 0px 20px 0px 5px;
  position: relative;
  font-size: 25px;
  margin-right: 10px;
}

.add_cartbtn .badge {
  border-radius: 100%;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  right: 8px;
  top: 3px;
  line-height: normal;
  background-color: #ff200c !important;
  box-sizing: border-box;
}

.logoutbtn,
.logoutbtn:focus,
.logoutbtn:active {
  padding: 0px 10px;
  font-size: 22px;
  height: 100%;
  background-color: #ff200c !important;
  border: 0px;
  border-radius: 0px;
  color: #fff !important;
  outline: none !important;
}

.logoutbtn:hover {
  background-color: #00be00 !important;
}

.header_back {
  float: left;
  width: 100%;
  border-bottom: 1px solid #2c59c0;
  margin-bottom: 20px;
  position: relative;
}

.header_backbtn {
  float: right;
  width: auto;
  font-size: 16px;
  line-height: normal;
  padding: 0px 8px 7px;
  box-sizing: border-box;
  border-radius: 11px;
  cursor: pointer;
  text-decoration: none;
}

.header_backbtn i {
  line-height: normal;
  float: left;
  width: auto;
}

.user_DETAILS .avatrshow {
  width: 27px;
}

.user_DETAILS .avatrshow img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* header css end here */

/* table filter */
.headerbtngroups {
  float: left;
  width: auto;
  align-items: center;
  display: flex;
}

.export_btncmn {
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 0 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--loginColor) !important;
  color: #fff !important;
  margin-left: 10px;
}

.export_btncmn:hover {
  background-color: #2769ff !important;
}

.graphs_columns {
  float: left;
  width: 100%;
  position: relative;
  margin: 5px 0 25px;
}

.productQuantyset {
  float: left;
  width: auto;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #eee;
  max-width: 130px;
  margin: -5px 0 0;
}

.cart_wrappperr li .cartbtn_wraps .quantity_product .productQuantyset {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: transparent;
  border: 0px;
  font-size: 18px;
}

.productQuantyset .btn {
  padding: 5px 5px;
  outline: none !important;
  border: 0px !important;
  margin: 0px 5px;
}

.productQuantyset .btn i {
  color: #6e6c6c;
}

.productQuantyBox {
  float: left;
  width: auto;
  word-break: break-all;
}

.redmednstar {
  color: red;
  font-size: 19px;
  line-height: 10px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.addQuantity i {
  color: var(--loginColor) !important;
}

.graphs_columns h4 {
  float: left;
  width: 100%;
  font-size: 17px;
  margin: 0 0 20px;
}

.piechartwraps {
  float: left;
}

.card-header-New {
  float: left;
  margin: 0 0 15px;
  width: 100%;
}

.card-header-New select {
  height: 36px !important;
  border-radius: 0px;
}

.searchBoxwithbtn {
  float: left;
  position: relative;
  width: 260px;
}

.searchBoxwithbtn .form-control {
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: none !important;
  font-size: 13px;
  height: 36px;
  padding: 7px 10px 7px 35px;
}

.searchBoxwithbtn .icon-tabler-search {
  position: absolute;
  left: 9px;
  top: 6px;
  width: 19px;
  color: #333;
}

.card-header-right {
  float: right;
  width: auto;
}

.activestatusselet,
.userLimitselect {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);
  background-position: 91%;
  background-repeat: no-repeat;
  background-size: 13px;
  border: 1px solid #ddd !important;
  border-radius: 0;
  cursor: pointer;
  float: left;
  font-size: 14px;
  height: 36px;
  width: 90px;
}
/* .status-icon
{
  background-image: url("../icons/statusSelect.svg") !important;
  background-position: 4% center !important;
  background-repeat: no-repeat !important;
  background-size: 14px !important;
  padding: 7px 35px 7px 21px !important;
} */

.card-header-right select:first-child {
  margin-right: 10px;
}

.card-header-right select:nth-child(2) {
  margin-right: 10px;
}

.card-header-right select {
  box-shadow: none !important;
}

.head_cmn_btn,
.head_cmn_btn:focus,
.head_cmn_btn:active {
  font-size: 14px;
  border-radius: 0px;
  margin: 0 0 0 10px;
  padding: 6.6px 10px;
  background-color: var(--loginColor) !important;
  color: #fff !important;
  border-color: var(--loginColor) !important;
}

.head_cmn_btn:hover {
  background-color: var(--maincolor) !important;
}

.head_reset_btn,
.head_reset_btn:focus,
.head_reset_btn:active {
  font-size: 14px;
  border-radius: 0px;
  margin: 0 0 0 10px;
  padding: 6.6px 10px;
  background-color: red !important;
  color: #fff !important;
  border-color: red !important;
}

.head_reset_btn:hover {
  background-color: red !important;
}

.cmn_export_btn,
.cmn_export_btn:focus,
.cmn_export_btn:active {
  font-size: 14px;
  border-radius: 0px;
  margin: 0 0 0 10px;
  padding: 6.6px 10px;
  background-color: #00b13a !important;
  color: #fff !important;
  border-color: #00b13a !important;
}

.cmn_export_btn:hover {
  background-color: #008d21 !important;
}

.cmn_export_btn img {
  width: 18px;
  line-height: normal;
  margin: 0px 3px 0px 0px;
}

.head_cmn_btn i {
  margin: 0 4px 0 0px;
}

.head_reset_btn i {
  margin: 0 4px 0 0px;
}

.searchBoxwithbtn .searchclear {
  float: right;
  width: auto;
  position: absolute;
  top: 9px;
  right: 9px;
  font-size: 13px;
  cursor: pointer;
  color: #333;
}

/* table filter */
.form-group label small {
  font-size: 11px;
  width: auto;
}

.filter_sectio {
  float: left;
  width: 100%;
}

.filter_sectio select {
  border: 1px solid #ddd !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  font-size: 13px !important;
  height: 36px !important;
  padding: 7px 35px 7px 10px !important;
}

/* table css */
.tableContent {
  float: left;
  width: 100%;
  position: relative;
}

.panelContentwrapper .table {
  border: 1px solid #eee;
  border-radius: 6px;
}

.form-group label .bi-info-circle-fill {
  float: right;
}

.panelContentwrapper .table td .form-group {
  margin-bottom: 0px;
}

.panelContentwrapper .table thead,
.panelContentwrapper .table tr,
tbody,
td,
tfoot,
th,
thead,
tr {
  float: none;
  width: auto;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 10px !important;
}

.panelContentwrapper .table thead th {
  background-color: #f3f7ff;
  font-size: 13px;
  padding: 12px 12px;
  font-weight: 500;
  color: #96a0b7;
}

.panelContentwrapper .table tbody td {
  font-size: 13px;
  padding: 9px 12px;
  vertical-align: middle;
  background-color: transparent;
}

.panelContentwrapper .table tbody tr:hover,
.panelContentwrapper .table tr.active {
  background-color: #f3f3f9;
}

.tablecollapsewraps {
  background-color: transparent !important;
}

.tablecollapsewraps table th {
  background-color: #f8f8f8;
  font-size: 12px;
  padding: 10px 12px;
  font-weight: 500;
}

.tablecollapsewraps table td {
  font-size: 12px;
}

.actionbtn {
  float: left;
  width: 17px;
  height: 17px;
  padding: 0px;
  border: 0px;
  outline: none !important;
  box-shadow: none;
  background: transparent;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.actionbtn img {
  float: left;
  height: 100%;
  width: 100%;
}

.actionbtn.active img {
  transform: rotate(180deg);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.panelContentwrapper .table tbody .actionbtn {
  margin-right: 12px;
}

.panelContentwrapper .table tbody .actionbtn:last-child {
  margin-right: 0px;
}

.panelContentwrapper .table tbody .actiontblebtn:last-child {
  margin-right: 0px;
}

.actiontblebtn {
  padding: 0px;
  font-size: 17px;
  background: transparent;
  outline: none !important;
  border: 0px !important;
  line-height: normal;
  float: left;
  width: auto;
  margin-right: 11px;
}

.actiontblebtn img {
  width: 16px;
}

.tooltip.show {
  position: fixed !important;
}

/* table css */

/* common toggle css */
.comnToggle {
  float: left;
  width: auto;
}

.comnToggle i {
  font-size: 25px;
  cursor: pointer;
  line-height: normal;
}

.comnToggle .bi-toggle2-on {
  color: var(--maincolortwo);
}

/* common toggle css */

/* dahsboard css start here */
.column_count_colus {
  float: left;
  width: 100%;
  display: flex;
  /* column-gap: 15px; */
  flex-wrap: wrap;
}

.column_count_colus .dashColswe {
  /* float: left;
  flex-basis: 19%; */
  border: 1px solid #dee3ea;
  background-color: #fff;
  /* margin: 0 0 20px; */
  padding: 20px 20px;
  text-decoration: none;
  border-radius: 0.7rem !important;
  margin-bottom: 1rem;
}

.column_count_colus .dashColswe .dashIcon {
  float: none;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 100%;
}

.dashColswe h1 {
  font-size: 40px;
  font-weight: 700;
  margin: 3px 5px 5px;
  color: #333;
}

.dashColswe h4 {
  font-size: 17px;
  margin: 0px;
  color: #959595;
  width: calc(100% - 76px);
}

.column_count_colus .dashColswe .dashIcon img {
  width: 26px;
}

/* dahsboard css end here */

/* product css start here */
.panelContentwrapper .table tbody td .avatrshow {
  float: left;
  width: 65px;
}

.panelContentwrapper .table tbody td .avatrshow img {
  width: 100%;
  border-radius: 50%;
}

/* product css end here */


#thumbnail-modal .modal-content {
  border: none !important;
}
textarea.form-control {
  height: 100px;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 14px;
  box-shadow: none !important;
  resize: none;
  font-weight: 500;
}

.filedaddwraps {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  column-gap: 10px;
}

.filedaddwraps .addbtn {
  background-color: #2c59c0;
  color: #fff;
  padding: 0px 5px;
  border-radius: 8px;
  font-size: 27px;
  line-height: normal;
}

.dimession_wraps {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.dimession_wraps input {
  height: 40px;
  border-radius: 8px;
  padding: 7px 12px;
  font-size: 13px;
  box-shadow: none !important;
}

.addbtncmn,
.addbtncmn:focus,
.addbtncmn:active {
  background-color: var(--loginColor) !important;
  color: #fff !important;
  padding: 7px 25px;
  font-size: 15px;
  border-radius: 8px;
  border-color: var(--loginColor) !important;
}

.addbtncmn:hover {
  background-color: var(--maincolortwo) !important;
  border-color: var(--maincolortwo) !important;
}

/* select end here */

.scrollformCustom {
  float: left;
  width: 100%;
  height: calc(100vh - 150px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* country code start here */
.multiPhoneCodeset .inputBoxSelect {
  width: 70px;
}

.selectNoInputField {
  float: left;
  width: 100%;
  border: 1px solid #e0e0e5;
  border-radius: 8px;
  position: relative;
  display: flex;
}

.selectNoInputField input {
  height: 40px;
  border: 0px;
  padding: 10px 10px;
  font-size: 13px;
  outline: none;
  width: 100%;
  border-radius: 8px;
}

.selectNoInputField .inputBoxSelect {
  width: 100px;
  text-align: left;
  margin: 0px;
  position: relative;
  padding-left: 10px;
}

.selectNoInputField .inputBoxSelect::after {
  content: "";
  float: left;
  width: 1px;
  height: 20px;
  background-color: #c1c1c1;
  position: absolute;
  right: 0;
  top: 11px;
}

.form-group.error .selectNoInputField {
  border-color: #f84646;
}

.form-group.error .form-groupfiled.expirydatswrapper .SiXSY,
.form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {
  border-color: #f84646 !important;
}

.countselet {
  font-size: 12px;
  float: left;
  width: auto;
  margin: 0 1px 0 0;
  color: #333;
}

.flagsDropdown {
  float: left;
  width: 220px;
  margin: 0px;
  padding: 0px;
  position: absolute !important;
  z-index: 9;
  background-color: #fff;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
  left: 0;
  bottom: 51px;
  top: auto;
  border: 0px;
}

.flagsDropdownMain a span {
  float: left;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flagsDropdownMain {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-height: 150px;
  overflow: auto;
}

.flagsDropdownMain a {
  float: left;
  width: 100%;
  list-style: none;
  display: flex;
  padding: 10px 13px;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  align-items: center;
}

.flagsDropdownMain a:hover {
  background-color: #eee;
}

.flagsDropdownMain a img {
  width: 26px;
  margin: 0px 8px 0px 0px;
}

.selectNoInputField .inputBoxSelect .selectEmojis {
  padding: 0px;
  border: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  background-color: transparent;
  margin: 0px;
}

.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0px 7px 0px 0px;
  object-fit: cover;
}

.noteemail {
  color: #9c9c9c;
  font-size: 15px;
}

.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {
  width: 7px;
  margin: 0px 0px 0px 3px;
}

.btn.formcomnbtn {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  width: 100%;
  padding: 11px 45px;
  float: right;
  font-size: 16px;
  color: #cecece;
  border-radius: 0;
}

/* country code end here */

.addtocartebtn,
.addtocartebtn:focus,
.addtocartebtn:active {
  background-color: var(--loginColor) !important;
  border-color: var(--loginColor) !important;
  color: #fff !important;
  font-size: 13px;
  border-radius: 30px;
  padding: 4px 8px;
}

.addtocartebtn:hover {
  background-color: var(--maincolortwo) !important;
  border-color: var(--maincolortwo) !important;
}

.btn.btn-clsoe {
  padding: 0px;
  color: red;
  font-size: 19px;
  outline: none !important;
  box-shadow: none !important;
  border: 0px !important;
}

.EmailMarginWrapper {
  float: left;
  width: 100%;
  position: relative;
}

.EmailMarginWrapper.active {
  margin: 15px 0 0;
}

.EmailMarginWrapper .form-group {
  margin-bottom: 12px;
}

/* cart css start here */
.cart_wrappperr {
  float: left;
  width: 100%;
  height: calc(100vh - 175px);
  padding: 0px;
  margin: 0 0 10px;
  position: relative;
}

.cart_wrappperr .ScrollbarsWrapper .view {
  padding-right: 15px !important;
  padding-bottom: 50px;
}

.cart_wrappperr li {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  list-style: none;
}

.cart_wrappperr li .avatrshow {
  float: left;
  width: 100px;
  height: 100px;
  margin-right: 15px;
  background-color: #eee;
}

.cart_wrappperr li .avatrshow img {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.cart_wrappperr li .cartdetails {
  float: left;
  width: 70%;
}

.cart_wrappperr li .cartdetails h4 {
  float: left;
  width: 100%;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 6px;
}

.cart_wrappperr li .cartdetails p {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  font-size: 14px;
}

.cart_wrappperr li .cartbtn_wraps {
  float: right;
  width: 140px;
  text-align: center;
  position: relative;
}

.logoutIconbtn {
  font-size: 40px;
  color: red;
  line-height: normal;
}

.cart_wrappperr li .cartbtn_wraps .quantity_product {
  float: left;
  width: 100%;
  padding: 10px 10px 6px;
  border: 1px solid #2c59c0;
  border-radius: 6px;
  font-size: 17px;
  position: relative;
  margin: 10px 0 8px;
}

ul.size_product {
  float: left;
}

.cart_wrappperr li .cartdetails .size_product {
  float: left;
  width: auto;
  padding: 0px;
}

.cart_wrappperr li .cartdetails .size_product li {
  margin: 0 0 5px;
  font-size: 13px;
}

.cart_wrappperr li .cartdetails .size_product li label {
  font-weight: 500;
}

.cart_wrappperr li .cartbtn_wraps .quantity_product label {
  float: none;
  width: auto;
  background-color: #fff;
  position: absolute;
  top: -11px;
  font-size: 14px;
  left: 0;
  right: 0;
  display: table;
  margin: 0 auto;
  padding: 0 10px;
  color: #2c59c0;
  font-weight: 500;
}

.cart_wrappperr li .cartbtn_wraps h2 {
  font-size: 28px;
  font-weight: 700;
  float: left;
  width: 100%;
}

.productcartList {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.cart_btn_group {
  float: left;
  width: 100%;
  margin: 10px 0 0;
}

.cart_btn_group .btn {
  margin-right: 20px;
}

.removercart {
  color: #f40000;
  font-size: 14px;
  padding: 0px;
  border: 0px;
  background: transparent;
}

.viewCartbtn {
  color: #28a745;
  font-size: 14px;
  padding: 0px;
  border: 0px;
  background: transparent;
}

.main_cart_wrapper {
  float: left;
  width: 100%;
  height: calc(100vh - 150px);
}

.finaltotoal_cart {
  float: left;
  width: 100%;
  position: absolute;
  padding: 15px 0px;
  left: 0;
  bottom: -15px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.supaTotalammount {
  float: right;
  width: auto;
  display: flex;
  align-items: center;
}

.supaTotalammount h1 {
  font-size: 29px;
  font-weight: 700;
  margin: 0px;
}

.supaTotalammount h4 {
  font-size: 18px;
  margin: 0px 12px 0px 0px;
}

.checkoutbtn,
.checkoutbtn:focus,
.checkoutbtn:active {
  background-color: var(--loginColor) !important;
  border-color: var(--loginColor) !important;
  color: #fff !important;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 14px;
  margin: 0 0 0 15px;
}

.checkoutbtn:hover {
  background-color: var(--maincolortwo) !important;
}

ul.tablehead_tabs {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px;
  border-bottom: 2px solid #2c59c0;
}

ul.tablehead_tabs li {
  float: left;
  width: auto;
  list-style: none;
  margin-right: 10px;
  position: relative;
  padding: 8px 25px;
  cursor: pointer;
  line-height: normal;
}

ul.tablehead_tabs li.active {
  background-color: #2c59c0;
  color: #fff;
}

.view_details .form-group label {
  font-weight: 500;
}

.view_details .form-group .text-show {
  float: left;
  width: 100%;
  background-color: #eee;
  padding: 10px 15px;
  font-size: 14px;
}

.checkoutbtn img {
  width: 20px;
  margin: 1px 3px 0 0;
  float: left;
}

.document_view {
  float: left;
  width: 100%;
  position: relative;
}

.documnt_column {
  float: left;
  width: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
  padding: 10px 15px;
  border: 1px solid #eee;
  cursor: pointer;
}

.documnt_column i {
  font-size: 30px;
}

.documnt_column .file_details {
  float: left;
  width: auto;
  flex: 1;
}

.documnt_column .file_details h4 {
  float: left;
  width: 100%;
  font-size: 17px;
  margin: 0 0 3px;
}

.documnt_column .file_details span {
  float: left;
  width: 100%;
  font-size: 13px;
  color: #7e7c7c;
}

.documnt_column:hover {
  border-color: #2c59c0;
  color: #2c59c0;
}

.download_file {
  border: 0px;
  background-color: rgb(30, 190, 44);
  padding: 5px 8px;
  line-height: normal;
  border-radius: 100%;
  color: #fff;
}

.download_file i {
  font-size: 15px;
}

.product_avatar {
  float: left;
  width: 100%;
  position: relative;
}

.product_avatar .avatrshow {
  float: left;
  width: 100%;
}

.product_avatar .avatrshow img {
  float: left;
  width: 100%;
  border-radius: 10px;
}

#ViewUser.product_avatar .avatrshow img {
  width: 128px !important;
  height: 128px !important;
}

#ViewOffer.product_avatar .avatrshow img {
  margin-top: 1rem;
  max-width: 180px;
  min-width: 180px;
  min-height: 180px;
  max-height: 180px;
}

li.emptycart {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
}

li.emptycart i {
  font-size: 45px;
  color: #ff200c !important;
}

.uploadbtn {
  position: absolute;
  right: 0;
  color: var(--loginColor);
  font-weight: 500;
  font-size: 13px;
  border: 1px solid var(--loginColor) !important;
  padding: 4px 6px;
  line-height: normal;
}

.uploadbtn i {
  font-size: 16px;
  margin: 0px 1px 0px 0px;
}

.form-sections {
  float: none;
  width: 100%;
  margin: 0 auto;
  display: table;
}

.inputText {
  background-color: #f5f5f5;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  box-shadow: none !important;
  cursor: no-drop;
  float: left;
  font-size: 13px;
  height: 40px;
  outline: none !important;
  padding: 8px 12px;
  width: 100%;
}

.headecenter {
  float: left;
  width: 100%;
  font-size: 20px;
  margin: 20px 0 20px;
  padding: 10px 0 0;
}

.errormendatorr {
  float: left;
  width: 100%;
  font-size: 13px;
  color: red;
  margin: 4px 0 0;
}

.filedsearchReltive {
  float: left;
  width: 100%;
  position: relative;
}

.filedsearchReltive .searchDropdownlist {
  max-height: 160px;
}

.filedsearchReltive .searchWrapper {
  border: 1px solid #ccc;
  border-radius: 8px !important;
  height: 100px;
  max-height: 100px !important;
  position: relative;
  overflow: auto;
  padding: 8.1px 12px;
  font-size: 13px;
  background-image: url("../img/careticons.png");
  background-repeat: no-repeat;
  background-position: 97% 15px;
  background-size: 16px;
}

/* pagination css start here */
.paginationCustom {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 9px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationCustom li:first-child,
.paginationCustom li:last-child {
  display: none;
}

.paginationCustom li .rpb-item--active {
  /* background-color: #0E0E30; */
  color: #000 !important;
}

.bg-grey {
  background-color: #dee3ea !important;
}

.editorClassName.rdw-editor-main {
  border: none !important;
}

.paginationCustom li button {
  width: 29px;
  height: 29px;
  min-width: 29px;
  font-size: 14px;
  border-radius: 100%;
  background-color: transparent;
  color: #8293bf;
  box-shadow: none !important;
}

.paginationCustom li:nth-child(2) {
  position: absolute;
  left: 2%;
}

.paginationCustom li:nth-child(2) button,
.paginationCustom li:nth-last-child(2) button {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.paginationCustom li:nth-last-child(2) {
  position: absolute;
  right: 3%;
  box-shadow: none !important;
  border-radius: 0 !important;
}

/* pagination css end here */

#vendor-tabbed-add-edit-tab-info,
#vendor-tabbed-add-edit-tab-media,
#add-edit-vendor .nav-tabs {
  border: none !important;
}

#vendor-tabbed-add-edit-tab-info label,
#vendor-tabbed-add-edit-tab-media label,
#add-edit-vendor label {
  font-weight: 600 !important;
}

.notformsection {
  float: left;
  width: 100%;
  text-align: center;
}

.notformsection p {
  float: left;
  width: 100%;
  font-size: 17px;
  margin: 0 0 7px;
}

.notformsection h5 {
  float: left;
  width: 100%;
  margin: 0px;
}

.addbtncmn .spinner-border {
  width: 20px;
  height: 20px;
  margin: 1px 6px 0px 0px;
  float: left;
}

.viewHeaderset {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
}

.viewHeaderset h4 {
  float: left;
  width: auto;
  font-size: 18px;
  margin: 0px;
  flex: 1;
}

.viewHeaderset .card-header-New {
  width: auto;
  margin: 0px;
  float: right;
}

.viewHeaderset .header_back {
  width: auto;
  margin: 0px;
  border: 0px;
}

.filedaddwraps.phoneListShow .form-group {
  margin-bottom: 0px;
}

.formplusbtonset .addbtn {
  margin: 10px 0 0;
}

.formplusbtonset .filedaddwraps.phoneListShow .addbtn {
  margin: 24px 0 0;
}

/* file upload css start here */
.fileUploadingform input.form-control {
  line-height: 27px;
  border-radius: 8px;
  font-size: 13px;
}

.filUploading {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: flex-start;
  column-gap: 8px;
  margin: 5px 0 0;
}

.filUploading .spinner-border {
  width: 15px;
  height: 15px;
  border-width: 2px;
}

.fileUploadingform input.form-control:disabled {
  cursor: not-allowed;
}

.uploadedImagewrps {
  float: left;
  width: 140px;
  height: 140px;
  position: relative;
}

.uploadedImagewrps .avatrshow {
  float: left;
  width: 100%;
  height: 100%;
}

.uploadedImagewrps img {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.uploadedImagewrps .closeIocn {
  float: left;
  width: auto;
  position: absolute;
  right: -15px;
  top: -10px;
  cursor: pointer;
}

.uploadedImagewrps .closeIocn i {
  line-height: normal;
  background-color: #fff;
  color: #ff0808;
}

/* file upload css end here */
.commonModal .modal-header {
  background: #f3f7ff !important;
  color: #000 !important;
  border-bottom: none;
  border-radius: 1rem !important;
}

.commonModal.addSupplierModel .modal-dialog {
  width: 100%;
  max-width: 95%;
}

#payment-success-modal .modal-content {
  border-radius: 4rem !important;
}

.modal-content {
  border-radius: 1rem;
}

.modal-footer {
  border-top: none;
}

.commonModal.addSupplierModel .modal-dialog .header_back {
  display: none;
}

.commonModal.addSupplierModel .modal-dialog .modal-footer {
  display: none;
}

.column_count {
  float: left;
  width: 100%;
  display: flex;
  column-gap: 10px;
}

.addmoreContact {
  float: left;
  width: 100%;
  position: relative;
  margin: 15px 0 0;
}

.addcontact_head {
  float: left;
  width: 100%;
  margin: 0 0 15px;
  position: relative;
}

.add_more_contact {
  float: left;
  width: auto;
  border-color: var(--loginColor) !important;
  color: var(--loginColor) !important;
}

.tablehead_tabs .header_back {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.addcontact_wrapper {
  float: left;
  width: 100%;
}

.tablehead_tabs .uploadbtn {
  right: 80px;
}

.view_details {
  float: left;
  width: 100%;
  height: calc(100vh - 120px);
}

.btn_deletebtn {
  border: 1px solid red !important;
  color: red !important;
  font-size: 14px;
  padding: 9px 10px;
  display: flex;
  column-gap: 3px;
}

.btn_successbtn {
  border: 1px solid var(--loginColor) !important;
  color: var(--loginColor) !important;
  font-size: 14px;
  padding: 9px 10px;
  display: flex;
  column-gap: 3px;
}

.add_morefunction {
  float: left;
  width: 100%;
  display: flex;
  column-gap: 10px;
}

.commn_loaderset {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  column-gap: 14px;
}

.commn_loaderset h4 {
  margin: 0px;
  font-size: 20px;
}

.tableviewdata {
  float: left;
  width: 100%;
}

.tablehead_tabs .header_back {
  border-bottom: 0px;
  margin-bottom: 0px;
  width: auto;
  float: right;
  position: absolute;
  right: 0;
}

.tablehead_tabs .header_back a.header_backbtn {
  padding: 0px 8px 0px;
}

.header_back_with_btn .header_back {
  margin: 0px;
  border: 0px;
  width: auto;
  float: right;
}

.header_back.header_back_with_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.import_product {
  background-color: #00b13a !important;
  color: #fff !important;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 0 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.import_product .spinner-border {
  width: 18px;
  height: 18px;
  border-width: 2px;
}

.import_product input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: -99;
}

.import_product i {
  margin: 0 0 0 6px;
}

.import_product:hover {
  background-color: #088832 !important;
}

.card-header-New.card-header-New-header {
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_wrapper .dropdown-toggle {
  padding: 0px;
  border: 0px;
}

.dropdown_wrapper .dropdown-toggle::after {
  display: none;
}

.dropdown_wrapper .dropdown-menu.show {
  width: 450px !;
  float: left !;
  max-height: 250px !;
  overflow: auto !;
}

.card-header-New.card-header-New-header .searchBoxwithbtn {
  width: 350px;
}

.card-header-New.card-header-New-header a.header_backbtn {
  padding: 0px 8px 0px;
}

a.vendor_admin_listing_datas.dropdown-item {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 6px 15px;
}

.vendor_admin_listing_datas .avatrshow {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 11px 0px 0px;
  flex: none;
}

.vendor_admin_listing_datas .addtocartebtn {
  font-size: 11px;
}

.vendor_admin_listing_datas .avatrshow img {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.dropdown_wrapper {
  float: left;
  width: 350px;
  position: relative;
}

.dropdown_wrapper ul.dropdown_view_sction {
  float: left;
  width: 500px;
  padding: 0px;
  border: 1px solid #ddd;
  position: absolute;
  left: 0;
  bottom: auto;
  top: 36px;
  background-color: #ffff;
  z-index: 999;
  max-height: 400px;
  overflow: auto;
}

.porduct_wrapper_data span {
  font-size: 11px;
  float: left;
  width: auto;
  margin: 0 0 1px;
}

.porduct_wrapper_data {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
}

.formInput label {
  font-size: 12px;
  padding: 4px 15px;
  background-color: #eee;
  float: left;
  width: auto;
  border-radius: 30px;
}

.login_country_custome.common-form.form-group input {
  padding-left: 15px;
}

.common-form.form-group .input-group-text,
.payment_mode .list-group-item span {
  font-weight: 400;
  font-size: 14px;
}

.common-form.form-group .input-group-text::after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 4;
  height: 40%;
  width: 1.3px;
  border-radius: 20px;
  background-color: var(--border-input);
}

.otp_wrapper input:empty {
  padding: 10px 12px 11px;
}

.otp_wrapper input {
  height: 51px !important;
  font-size: 13px;
  box-shadow: none;
  border-radius: 20%;
  width: 53px !important;
  border: 1px solid #ccc;
  outline: none !important;
  margin-right: 0 !important;
}

.column_count_Main {
  float: left;
  width: 100%;
}

.text-left {
  text-align: left !important;
}

.common-voilet-bg {
  background-color: #f1e7ff !important;
}

.common-blue-bg {
  background-color: var(--loginColor) !important;
  color: #fff !important;
}

.view-btn {
  background-color: #14ce5e !important;
  color: #fff;
  padding: 2px 6px;
}

.share-btn {
  background-color: #ff8730 !important;
  color: #fff;
  padding: 2px 6px;
}

.edit-btn {
  background: #f7a529 !important;
  color: #fff;
  padding: 2px 6px;
}

.delete-btn {
  background: #e23e3e !important;
  color: #fff !important;
  padding: 2px 6px;
}

.delete-text {
  color: #e23e3e !important;
}
.col-custom-dashboard-card {
  width: calc(calc(100% / 3) - 10px);
}
.common-blue {
  color: var(--loginColor) !important;
}

.common-border {
  border: 1px solid #dee3ea !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}

.float-right {
  float: right !important;
}

.secondary-text {
  color: #8293bf !important;
}

.secondary-bg {
  background-color: #f6f9ff !important;
  border: 0.5px solid #e3e5e9;
}

.bg-blue {
  background-color: #e7efff !important;
  width: fit-content;
  border-radius: 6px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #407bff !important;
  border-bottom: 3px solid #407bff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #000 !important;
  border: none;
}

.dashboard_wrapper .info {
  padding: 10px 15px;
}

.item .item-content {
  padding: 30px 35px;
}

.image-upload {
  width: 100%;
  height: 300px;
  border: 1px dashed #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  background: #f8f8f9;
  color: #666;
  overflow: hidden;
}

.item-wrapper form img {
  margin-bottom: 20px;
  width: auto;
  height: auto;
  max-height: 400px;
  width: auto;
  border-radius: 5px;
  overflow: hidden;
}

.image-upload img {
  height: 100% !important;
  width: auto !important;
  border-radius: 0px;
  margin: 0 auto;
}

.image-upload i {
  font-size: 6em;
  color: #ccc;
}

.image-upload input {
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.item-wrapper input {
  height: 43px;
  line-height: 43px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.breadcrumb {
  font-size: 14px;
  margin-bottom: 10px;
}

.breadcrumb a {
  text-decoration: none;
  color: #007bff;
}

.breadcrumb span {
  margin: 0 5px;
  color: #555;
}

.breadcrumb span:last-child {
  color: #333;
}

.number-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 8px;
  font-size: 12px;
  width: 16px;
  height: 16px;
  background-color: #eee;
  border-radius: 20px;
}

.file-upload-form .upload-icon {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* AddEditVendor.css */
.draggable-div {
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  padding: 16px;
  cursor: move;
}

#live-map-file-upload .file-upload-form,
#add-edit-event .file-upload-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

#live-map-file-upload .file-upload-label input,
#add-edit-event .file-upload-label input {
  display: none;
}

#live-map-file-upload .file-upload-label svg,
#add-edit-event .file-upload-label svg {
  height: 50px;
  fill: rgb(82, 82, 82);
  margin-bottom: 20px;
}

#live-map-file-upload .file-upload-label,
#add-edit-event .file-upload-label {
  cursor: pointer;
  background-color: #eff1f5;
  padding: 2% 30%;
  border: 3px dashed #407bff;
}

#live-map-file-upload .file-upload-design,
#add-edit-event .file-upload-design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

#live-map-file-upload .browse-button,
#add-edit-event .browse-button {
  background-color: #eff1f5;
  padding: 5px 15px;
  border-radius: 10px;
  color: white;
  transition: all 0.3s;
}
.top-1 {
  top: 1% !important;
}
.start-3 {
  left: 3% !important;
}
.bg-none {
  background: none !important;
}
.total-user-count-heading {
  z-index: 100;
  top: 2%;
  right: 8%;
  font-weight: bold;
  /* background: white; */
  padding: 5px 10px;
}

#manage-svg svg {
  border-radius: 15px !important;
}

#chartsandtables .col-md-6 svg {
  border-radius: 0.8rem !important;
}

#chartsandtables .col-md-6 img {
  max-width: 2rem;
}

#chartsandtables .col-md-12 img {
  max-width: 3rem;
}

#user-pie-chart circle[fill="#3366cc"] {
  fill: #ff5b5b;
  /* Change to your desired green color */
}

#user-pie-chart circle[fill="#dc3912"] {
  fill: #ffe6e6;
  /* Change to your desired black color */
}

#user-pie-chart path[fill="#3366cc"] {
  fill: #ff5b5b;
  /* Change to your desired green color */
}

#user-pie-chart path[fill="#dc3912"] {
  fill: #ffe6e6;
  /* Change to your desired black color */
}

#vendor-pie-chart circle[fill="#3366cc"] {
  fill: #00b074;
  /* Change to your desired green color */
}

/* Inactive color (Black) */
#vendor-pie-chart circle[fill="#dc3912"] {
  fill: #d6f0e7;
  /* Change to your desired black color */
}

/* Active path color (Green) */
#vendor-pie-chart path[fill="#3366cc"] {
  fill: #00b074;
  /* Change to your desired green color */
}

/* Inactive path color (Black) */
#vendor-pie-chart path[fill="#dc3912"] {
  fill: #d6f0e7;
  /* Change to your desired black color */
}

#vendor-pie-chart text[fill="#ffffff"],
#vendor-pie-chart text[fill="#ffffff"],
#user-pie-chart text[fill="#ffffff"],
#user-pie-chart text[fill="#ffffff"] {
  fill: #000000;
  /* Change to your desired text color */
}

#VendorChart1 circle[fill="#3366cc"] {
  fill: #29cc39;
  /* Change to your desired green color */
}

/* Inactive color (Black) */
#VendorChart1 circle[fill="#dc3912"] {
  fill: #ff6633;
  /* Change to your desired black color */
}

#VendorChart1 circle[fill="#ff9900"] {
  fill: #8833ff;
  /* Change to your desired black color */
}

/* Active path color (Green) */
#VendorChart1 path[fill="#3366cc"] {
  fill: #29cc39;
  /* Change to your desired green color */
}

/* Inactive path color (Black) */
#VendorChart1 path[fill="#dc3912"] {
  fill: #ff6633;
  /* Change to your desired black color */
}

#VendorChart1 path[fill="#ff9900"] {
  fill: #8833ff;
  /* Change to your desired black color */
}

#VendorChart2 circle[fill="#3366cc"] {
  fill: #ffdb5a;
  /* Change to your desired green color */
}

/* Inactive color (Black) */
#VendorChart2 circle[fill="#dc3912"] {
  fill: #d257e6;
  /* Change to your desired black color */
}

/* Active path color (Green) */
#VendorChart2 path[fill="#3366cc"] {
  fill: #ffdb5a;
  /* Change to your desired green color */
}

/* Inactive path color (Black) */
#VendorChart2 path[fill="#dc3912"] {
  fill: #d257e6;
  /* Change to your desired black color */
}
#chartsandtables text[fill="#000000"] {
  font-size: 1rem !important;
}
.react-datepicker__input-container input,
.react-time-picker__inputGroup input,
.react-time-picker__wrapper {
  height: 2rem;
  border-radius: 6px;
}

.react-datepicker__input-container input,
.react-time-picker__wrapper {
  border: 1px solid #dee2e6 !important;
}

#customer-padding-for-label {
  padding: 6% 14% 0% 14% !important;
}

select.form-control {
  height: 40px;
  border-radius: 8px;
  padding: 7px 21px 7px 10px;
  font-size: 13px;
  box-shadow: none !important;
  background-image: url("../img/careticons.png");
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: 12px;
}

/* .css-13cymwt-control {
  border-radius: 6px !important;
}
select.form-control,
.css-13cymwt-control {
  height: 40px;
  border-radius: 8px;
  padding: 7px 21px 7px 10px;
  font-size: 13px;
  box-shadow: none !important;
  background-image: url("../img/careticons.png");
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: 12px;
}
.css-13cymwt-control {
  padding: 0;
  height: auto !important;
}
.css-1xc3v61-indicatorContainer svg {
  display: none !important;
}
.css-1jqq78o-placeholder {
  border-radius: 8px;
  font-size: 14px;
  box-shadow: none !important;
  font-weight: 500;
  color: #2c2b3d !important;
}
.css-tj5bde-Svg {
  display: none !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: white !important;
  display: none !important;
} */
input.form-control {
  text-overflow: ellipsis;
}

#open-close-add-edit-vendor input {
  border: 1px solid #e3e5e9 !important;
  padding: 0.3rem;
  border-radius: 6px !important;
}


#view-user-gradient img {
  height: 30px;
  width: 30px;
}

.vendor-dashboard-card-bg {
  background-image: url("../icons/cardBG.png");
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  padding: 1rem;
}

#vendor-dashboard-info-card img {
  border-radius: 50% !important;
}
#add-edit-event-media #playIcon {
  left: 23% !important;
}
#controlled-tab-user-tabpane-gallery .view_details {
  height: calc(100vh - 305px);
}
.custom-width-calculate-for-graph {
  width: calc(100% + 1900%) !important;
}

.form-control,
input,
select {
  color: #2c2b3d !important;
}

.ReactModal__Overlay {
  z-index: 13;
}
#user-avatar img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.drag-icon-container {
  position: absolute;
  top: 50%;
  right: -30%;
  transform: translate(-50%, -50%);
}
#view-user-tab ul {
  padding: 0 10px;
}
.col-md-custom {
  width: 80% !important;
}
.col-md-11percent {
  width: 5% !important;
}
#new-reg text[fill="#000000"] {
  margin-top: 1rem;
}
.spanactive {
  border-left: 4px solid var(--loginColor);
  margin: 8px 0;
  /* background-image: url("../img/activeBar.png"); */
  background-repeat: no-repeat;
  border-radius: 0 20px 0 20px;
}
#my-business ul,
#my-business .nav-tabs .nav-link.active {
  background-color: #f3f7ff !important;
  border-radius: 6px;
}
#my-business ul {
  border: 1px solid #f3f7ff !important;
}
#list-avatar {
  align-items: center;
  gap: 5px;
}
#list-avatar img {
  height: 30px;
  width: 30px;
}
#list-avatar .avatrshow {
  width: 40px;
}
.left-21px {
  left: 21px !important;
}
.preview_container.form-control {
  border-radius: 6px !important;
}
#starting_on .input-group {
  border-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  overflow: auto !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}
.react-datepicker__input-container input{
  height: 2.5rem !important;
}
.scrollable-height-vendor-dashboard{
  height: calc(100vh - -270px) !important;
}
.css-1nmdiq5-menu{
  z-index: 1000 !important;
}
.privacy-policy-container {
  overflow-y: auto; /* Ensure vertical scrolling */
  height: 100vh; /* Full height to enable scrolling */
  padding: 20px; /* Add some padding for better readability */
  box-sizing: border-box; /* Include padding in height calculation */
}
.leaflet-control-attribution.leaflet-control a{
  display: none;
}