/* Font weight classes for Bootstrap 5 */
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/* Bootstrap 5 simplified font weight classes */
.fw-normal {
  font-weight: normal !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-lighter {
  font-weight: lighter !important;
}
.fs-10px{
  font-size: 10px !important;
}
.fs-12px{
  font-size: 12px !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.w-max-content{
  width: max-content !important;
}
.golden {
  color: #ffc700 !important;
}
.w-40px{
  width: 56px !important;
}
.mb--10px{
  margin-bottom: -10px !important;
}
.mt--18px{
  margin-top: -18px !important;
}
.aic{
  align-items: center;
}
.jcc{
  justify-content: center;
}
.capitalize{
  text-transform: capitalize !important;
}
.badge-gradient::before {
  /* border-image-source: linear-gradient(125.94deg, #CCCEFF 16.15%, #FFCCCC 91.04%) !important; */
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/badgeBG.png");
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  top: 0px;
  left: 0px;
}
.badge-gradient h5{
  font-size: 14px;
  font-weight: 300px !important;
}


.coin-gradient::before {
  /* border-image-source: linear-gradient(180deg, #E7F2FF 0%, #A3DEFF 100%) !important; */
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/coinBG.png");
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.coin-gradient h5 {
  color: #000;
}
.visibility-hidden{
  visibility: hidden;
}
.visibility-visible{
  visibility: visible;
}
.br-0{
  border-radius: 0px !important;
}
.referral-gradient::before {
  /* border-image-source: linear-gradient(180deg, #FFE7E7 0%, #FFE0A3 100%) !important; */
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/referralBG.png");
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.referral-gradient h5 {
  color: #ffe0a3;
}

.rr-gradient::before {
  /* border-image-source: linear-gradient(180deg, #FCE7FF 0%, #A3DEFF 100%) !important; */
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/rrBG.png");
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.rr-gradient h5 {
  color: #a3deff;
}

.media-gradient::before {
  /* border-image-source: linear-gradient(180deg, #E8FF8B 0%, #62F7B9 100%) !important; */
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/mediaBG.png");
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.media-gradient h5 {
  color: #62f7b9;
}

.total-yes-gradient::before {
  /* border-image-source: linear-gradient(180deg, #D3FFCC 0%, #D3FFCC 100%) !important; */
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/TotalYesBG.png");
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.total-yes-gradient h5 {
  color: #d3ffcc;
}

.total-maybe-gradient::before {
  /* border-image-source: linear-gradient(180deg, #FFECB9 0%, #FFECB9 100%) !important; */
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/maybeBG.png");
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.total-maybe-gradient h5 {
  color: #ffecb9;
}

.total-no-gradient::before {
  /* border-image-source: linear-gradient(180deg, #FFC6C6 0%, #FFC6C6 100%) !important; */
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/noBG.png");
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.total-no-gradient h5 {
  color: #ffc6c6;
}

.border-right {
  border-right: 1px solid #dee3ea !important;
}

.text-green {
  color: #165e3d !important;
}

.bg-green {
  background-color: #edffea !important;
}

.text-pending {
  color: #b5850b !important;
}

.bg-pending {
  background-color: #fff6e9 !important;
}

.text-decline {
  color: #b83131 !important;
}

.text-create {
  color: #3d42ad !important;
}

.bg-create {
  background-color: #eaecff !important;
}

.bg-decline {
  background-color: #ffeaea !important;
}

.font-normal {
  font-style: normal !important;
}

.setting-count {
  color: #fff;
  font-size: 70%;
  background-color: #e74c3c;
  position: absolute;
  top: 45%;
  left: 80%;
  -webkit-transform: translate(-50%, -90%);
  -moz-transform: translate(-50%, -90%);
  transform: translate(-50%, -90%);
  padding: 0px 12%;
  border-radius: 1000px;
}

.pt-07rem {
  padding-top: 0.7rem !important;
}

.pink-bg {
  background-color: #ff5bdb;
  color: #fff;
}

.orange-bg {
  background-color: #ff8f6b;
  color: #fff;
}

.dark-blue-bg {
  background-color: #605bff;
  color: #fff;
}

.green-bg {
  background-color: #7cba5f;
  color: #fff;
}

.light-blue-bg {
  background-color: #5b93ff;
  color: #fff;
}

.dark-yellow-bg {
  background-color: #ffc327;
  color: #fff;
}



.css-b62m3t-container {
  width: 100% !important;
}

.bg-f8 {
  background-color: #f8f9fc !important;
}

.text-red {
  color: red !important;
}

.discard-btn {
  background-color: #ff2e8f !important;
  color: #fff;
}

.text-pink {
  color: #ff4085;
}

.text-voilet {
  color: #c550ee !important;
}
.text-voilet2{
  color: #AD30F9 !important;
}

.bg-pink {
  background-color: #ff4085 !important;
}
.bg-paid{
  background-color: #95949E;
}
.bg-declined {
  background-color: #ffcbcb !important;
}

.grey-gradient {
  background: linear-gradient(
    180deg,
    rgba(232, 232, 255, 0.5) 0%,
    rgba(217, 217, 217, 0) 100%
  ) !important;
}

.text-light-purpule {
  color: #8884c5;
}

.border-dotted {
  border: 2px dashed #d1d1d1 !important;
  /* You can customize the width and color */
  border-radius: 10px;
}

.border-bottom-dotted {
  border-bottom: 2px dashed #e3e8ef !important;
  /* You can customize the width and color */
  border-radius: 10px;
}

.col-13percent {
  width: 13% !important;
}

.mt--1 {
  margin-top: -1rem;
}

.br-1rem {
  border-radius: 1rem !important;
}

.bg-red {
  background-color: red !important;
}

.w-20 {
  width: 20% !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2rem {
  width: 2rem !important;
}
.h-100px{
  height: 100px !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-1-5rem {
  height: 1.5rem !important;
}

.w-1-5rem {
  width: 1.5rem !important;
}

.fs-1-3rem {
  font-size: 1.3rem !important;
}

.light-grey-bg {
  background-color: #f3f7ff !important;
}

.bg-yellow {
  background-color: #ffc01e !important;
}

.logincolor {
  color: #407bff;
}
.mt--3{
  margin-top: -3rem;
}
.mt--2{
  margin-top: -2rem;
}
.br-50 {
  border-radius: 50% !important;
}
.top-83{
  top: 83% !important;
}
.bb-dashed {
  border-bottom: 2px dashed #e2e7f5 !important;
}
.faq-bg{
  background-color: #F8F9FC !important;
}
.top--5 {
  top: -5%;
}

.end--7 {
  right: -7% !important;
}

.w-3rem {
  width: 3rem !important;
}

.text-choco {
  color: #93360f !important;
}

.yes-cnt {
  color: #0dc155 !important;
}

.maybe-cnt {
  color: #feb900 !important;
}

.no-cnt {
  color: #f43333 !important;
}

.flex-auto {
  flex: auto !important;
}
.z-100{
  z-index: 100 !important;
}
.z-1000{
  z-index: 1000 !important;
}
.w-33 {
  width: 33% !important;
}

.w-auto {
  width: auto !important;
}

.h-auto {
  height: auto !important;
}
.overflow-x-hidden{
  overflow-x: hidden !important;
}

.bg-highlight-pink {
  background-color: #ffecf3 !important;
  border: 1px solid #f0d2dd;
}

.text-fuchsia {
  color: #ff4085 !important;
}

.vertical-line {
  display: inline-block;
  border-left: 1px solid #ffc6db !important;
  height: 1.5rem;
  /* Adjust the height as needed */
  margin: 0 10px;
  /* Adjust the margin as needed */
}

.border-top-dashed {
  border-top: 2px dashed #e3e8ef !important;
}

.text-parrot {
  color: #14ce5e !important;
}

.text-live {
  color: #2253ff !important;
}

.text-expire {
  color: #93360f !important;
}
.top-auto{
  top: auto !important;
}
.start-auto{
  left: auto !important;
}

#payment-modal .react-datepicker__input-container input {
  height: 2.5rem;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.yes-green-bg {
  background-color: #29cc39 !important;
}

.yes-green-bg button {
  background-color: #14b324 !important;
  border: 1px solid #14b324;
}

.maybe-voilet-bg {
  background-color: #8833ff !important;
}

.maybe-voilet-bg button {
  background-color: #6721c9 !important;
  border: 1px solid #6721c9;
}

.no-red-bg {
  background-color: #ff6633 !important;
}

.no-red-bg button {
  background-color: #df5425 !important;
  border: 1px solid #df5425;
}

.visited-blue-bg {
  background-color: #33bfff !important;
}

.visited-blue-bg button {
  background-color: #27a1d9 !important;
  border: 1px solid #27a1d9;
}

.offers-black-bg {
  background-color: #1a2233 !important;
}

.offers-black-bg button {
  background-color: #080b13 !important;
  border: 1px solid #080b13;
}

.w-10rem {
  width: 10rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.icon-bg-grey {
  background-color: #e6e9f2 !important;
}

.max-w-50 {
  max-width: 50% !important;
}

.border {
  border-radius: 6px;
}

.end-65 {
  right: 65% !important;
}

.title {
  color: #5d6e92 !important;
}

.w-24 {
  width: 24% !important;
}

.br-2rem {
  border-radius: 2rem !important;
}

.tertiary-heading {
  color: #5682e2 !important;
}

.secondary-heading {
  color: #aac5ff;
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.end-1rem {
  bottom: 1rem !important;
}

.progress-line-container {
  width: 100%;
  height: 5px;
  background-color: #f0f0f0;
  /* Background color of the progress line container */
  border-radius: 5px;
  /* Rounded corners for the container */
  overflow: hidden;
  /* Hide overflow */
}
.overflow-y-scroll{
  overflow-y: scroll !important;

}
.overflow-x-hidden{
  overflow-x: hidden !important;
}
.w-20{
  width: 20% !important;
}
.w-18{
  width: 18% !important;
}

.progress-line {
  height: 100%;
  background-color: #14ce5e;
  /* Color of the filled progress line */
}

.fs-12px {
  font-size: 12px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.fs-14px {
  font-size: 14px !important;
}
.fs-15px {
  font-size: 15px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.fs-17px {
  font-size: 17px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-19px {
  font-size: 19px !important;
}
.fs-20px {
  font-size: 20px !important;
}

.ml--12px{
margin-left: -12px !important;
}
.text-small {
  color: #8293bf;
}
.no-btn{
  background-color: #D7DEEC !important;
  border: 1px solid #D7DEEC !important;

}
.no-text{
  color: #6175A7 !important;
}
.mx-2-5 {
  margin-right: 0.5rem !important;
  margin-left: 0.8rem !important;
}
.w-33{
  width: 33% !important;
}
.w-98{
  width: 98% !important;
}.w-96{
  width: 96% !important;
}.w-94{
  width: 94% !important;
}.w-92{
  width: 92% !important;
}.w-90{
  width: 90% !important;
}
.w-95{
  width: 95% !important;
}
.border-bottom {
  border-bottom: 1px solid #f5f6f7 !important;
}
.payment-declined-bg{
  background-color: #FFCBCB !important;
}
.payment-declined-text{
  background-color: #E23E3E !important;
}
.h-fit-content{
  height: fit-content !important;
}
.h-90{
  height: 90% !important;
}
.h-96{
  height: 96% !important;
}
.h-90{
  height: 90% !important;
}
.h-92{
  height: 90% !important;
}
.h-94{
  height: 94% !important;
}
.text-96{
  color: #96A0B7;
}
.total-card-heading{
  color: #030229 !important;
  font-weight: 400 !important;
}
.word-wrap-anywhere{
  word-wrap: anywhere !important;
}
.text-align-left{
  text-align: left !important;
}
