.time_picker_container {
  position: relative;
  display: block;
}

.time_picker_preview.disabled {
  cursor: not-allowed;
}

.preview_container {
  --bs-body-font-size: 14px;
  --text-color: var(--bs-body-color);
  --fr-border-radius: var(--bs-border-radius);
  --fr-border-color: #cdd2da;
  --fr-border-width: 1px;
  --fr-border-style: solid;
  --fr-font-size: var(--bs-body-font-size);
  position: relative;
  padding-left: 40px;
  padding-top: 0.65rem;
  padding-right: 1rem;
  border-radius: var(--fr-border-radius);
  padding-bottom: 0.65rem;
  font-size: var(--fr-font-size);
  border: var(--fr-border-width) var(--fr-border-style) var(--fr-border-color);
  display: flex;
  align-items: center;
}

.preview_container.without_icon {
  padding-right: 30px;
}

.wrapper-clock .input-group svg {
  /* width: 38px; */
  height: 100%;
  /* position: absolute; */
  /* padding: 10px; */
  /* left: 0px; */
  /* top: 0px; */
}
/* .wrapper-clock .input-group .input-group-text{
  position: absolute;
  height: 100%;
  right: 0px;
} */
.black-border .input-group{--fr-border-focus-color:#eee;--fr-border-color:#000}

.wrapper-clock .input-group{flex-wrap: nowrap;}

.wrapper-clock .input-group:focus-within .input-group-text{    border-color: var(--fr-border-focus-color);}
.wrapper-clock .input-group .input-group-text, .wrapper-clock .input-group  input{border-color: var(--fr-border-color);}
.wrapper-clock .input-group .preview_container.form-control{transition: none!important;}
.wrapper-clock .input-group .preview_container.form-control:not(:last-child){padding-right: 0px!important;border-right-width: 0px!important;border-top-right-radius: 0px!important;border-bottom-right-radius: 0px!important;}
.wrapper-clock .input-group .preview_container.form-control:not(:first-child){padding-left: 0px!important; border-left-width:  0px!important;border-top-left-radius: 0px!important;border-bottom-left-radius: 0px!important}
.wrapper-clock .input-group .input-group-text:first-child{border-right-width: 0px!important;--bs-tertiary-bg:transparent}
.wrapper-clock .input-group .input-group-text:last-child{border-left-width: 0px!important;--bs-tertiary-bg:transparent}
.wrapper-clock .circle-close::before{border-radius: 40px;background-color: #216ba5;color: white;font-size: 12px;padding: 2px;display: flex;align-items: center;justify-content: center;}
/* .input-group .input-group-text{border-width: var(--fr-border-width)!important;} */
.react_times_button {user-select: none;position: relative;cursor: pointer;color: #343434;border-radius: 2px;background-color: #fff;transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);-ms-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);-moz-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);-o-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);-webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);width: auto;}
.form_variate.form-validate .preview_container{--fr-border-color:#dc3545}

.react_times_button.pressDown {box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);-moz-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);-webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);}
.react_times_button.pressDown .wrapper {transform: translateY(1px);}
.react_times_button .wrapper {transform: translateY(0);height: 100%;}
.modal_container {user-select: none;cursor: default;position: absolute;width: 100%;transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);-ms-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);-moz-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);-o-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);-webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);background-color: #fff;border-radius: 2px;top: 100%;left: 0;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;-moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;-webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;opacity: 0;z-index: -1;visibility: hidden;backface-visibility: hidden;transform: scale(0.7) translateY(20px);-ms-transform: scale(0.7) translateY(20px);-moz-transform: scale(0.7) translateY(20px);-o-transform: scale(0.7) translateY(20px);-webkit-transform: scale(0.7) translateY(20px);border: 1px solid #aeaeae;}
.outside_container.active .modal_container {opacity: 1;z-index: 2;visibility: visible;transform: scale(1) translateY(10px);-ms-transform: scale(1) translateY(10px);-moz-transform: scale(1) translateY(10px);-o-transform: scale(1) translateY(10px);-webkit-transform: scale(1) translateY(10px);width: auto;display: block;border-radius: 5px;}
.time_picker_modal_header, .time_picker_modal_footer, .timezone_picker_modal_header{border-radius: 5px 5px 0px 0px;border-bottom: 1px solid #ccc;}
/* .time_picker_modal_header::after, .time_picker_modal_header::before{
  box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: 0;
    border-width: 8px;
    left: 6px;
} */
/* .time_picker_modal_header::before{top: -16px;border-bottom-color: #dbd6d6;}
.time_picker_modal_header::after{top: 15px;} */
.react_times_button .wrapper-clock {
  transform: translateY(0);
  height: 100%;
}